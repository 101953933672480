
/* Animation to shake elements in X position when hover*/
@keyframes shakeX {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-2px) rotate(-1deg);
    }

    50% {
        transform: translateX(2px) rotate(1deg);
    }

    75% {
        transform: translateX(-2px) rotate(-1deg);
    }

    100% {
        transform: translateX(0);
    }
}

.shakeElement {
    transition: transform 0.3s;
}

.shakeElement:hover {
    animation: shakeX 0.3s;
}
/* END Animation to shake elements in X position */