@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Class to add as modal background color */
.bg-modal-shadow {
  background-color: rgba(0, 0, 0, 0.4);
}

.green-app-bg {
  background-color: #364b38
  ;
}

.green-light-app-bg {
  background-color: #7e9d7d
  ;
}

.gray-app-bg {
  background-color: #f3f3f3
  ;
}

.dark-text {
  color: #2b2b2b
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}